@font-face {
  font-family: 'SFProRounded';
  src: local('SFProRounded'), url('./fonts/SF-Pro-Rounded-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFProRoundedMedium';
  src: local('SFProRounded'), url('./fonts/SF-Pro-Rounded-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'SFProRounded', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div {
  box-sizing: border-box;
}
p {
  margin: 0;
}

html, body {
  height: 100%;
  width: 100%;
}


#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
