:global {
  .ant-modal .ant-modal-content {
    min-height: 560px;
    border-radius: 20px;
    background: #EEEEEE;
  }
  .ant-carousel .slick-dots li button{
    width: 10px;
    height: 10px;
    opacity: 1;
    border-radius: 50%;
  }
  .ant-carousel .slick-dots li.slick-active{
    width: 15px;
  }
  .ant-carousel .slick-dots li.slick-active button{
    width: 10px;
    height: 10px;
    background: rgba(17, 212, 157, 1);
  }
  .ant-progress-line{
    margin-bottom: 0;
  }
  .ant-progress-inner{
    //margin-bottom: 6px;
  }
  .ant-switch.ant-switch-checked{
    background: rgba(17, 212, 157, 1) !important;
  }
}
.DModal {
  background: #eeeeee;
  border-radius: 20px;
  padding: 0 20px 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
  .DModalTitle {
    font-size: 28px;
    font-weight: 800;
    color: #0D1421;
    text-align: center;
    margin-bottom: 40px;
  }
  .DModalTitleTips {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #0D1421;
  }
  .DModalInput {
    height: 64px;
    box-shadow: 0px 7px 18px 0px rgba(88,102,126,0.11);
    border-radius: 8px;
    border: none;
    font-size: 20px;
  }
  .DModalBtnWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
    .DModalBtnDel {
      cursor: pointer;
      background: #FF5E5F;
      border-radius: 12px;
      padding: 5px 30px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
    }
    .DModalBtnDone {
      cursor: pointer;
      background: #11D49D;
      border-radius: 12px;
      padding: 5px 30px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
    }
  }
}
.backWrap{
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: #F3F3F3;
  box-shadow: 0 10px 25px 0 rgba(88,102,126,0.11);
  border-radius: 50%;
  cursor: pointer;
  .backImg{
    width: 26px;
    height: 26px;
  }
}
.title{
  text-align: center;
  font-size: 28px;
  font-weight: 800;
  color: #0D1421;
}
.tips{
  text-align: center;
  font-size: 14px;
  color: #58667E;
}
.optionWrap{
  display: flex;
  align-items: center;
  padding: 10px 0;
  .selectImg{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .searchText{
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #0D1421;
  }
}
.middleWrap{
  display: flex;
  margin-top: 30px;
  .carouselRightWrap{
    width: 500px;
    .searchWrap{
      .searchTitle{
        font-size: 16px;
        font-weight: 600;
        color: #0D1421;
      }
      .iptWrap{
        display: flex;
        align-items: center;
        width: 481px;
        height: 48px;
        margin-top: 15px;
        padding: 0 8px;
        background: #FFFFFF;
        border-radius: 8px;
        .searchImg{
          width: 16px;
          height: 16px;
        }
      }
      .radioBoxWrap{
        margin-top: 30px;
        .selectTitle{
          font-size: 16px;
          font-weight: 600;
          color: #0D1421;
        }
        .radioBoxList{
          display: flex;
          flex-wrap: wrap;
          min-height: 40px;
          max-height: 130px;
          overflow-y: auto;
          .radioItem{
            width: 136px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            .radioRightWrap{
              display: flex;
              align-items: center;
              margin-top: 4px;
              .radioIcon{
                width: 26px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                //border-radius: 14px;
              }
              .radioText{
                margin-left: 10px;
                font-size: 14px;
                color: #58667E;
              }
            }
            .selectImg{
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
          .radioItem:not(:nth-child(3n + 1)){
            margin-left: 30px;
          }
        }
      }
      .checkBoxWrap{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .selectTitle{
          font-size: 16px;
          font-weight: 600;
          color: #0D1421;
        }
        .switchWrap{
          margin-right: 30px;
        }
      }
      .checkBoxTips{
        margin-top: 5px;
        font-size: 12px;
        color: #58667E;
      }
      .tgCheckBoxWrap{
        margin-top: 15px;
        width: 469px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 15px;
        border: 1px solid #E3E5F4;
        .tgWrap{
          display: flex;
          align-items: center;
          padding: 0 16px;
          .tgItem{
            width: 146px;
            padding: 13px 0;
            font-size: 14px;
            font-weight: 500;
            color: #0D1421;
          }
          .tgItem2{
            width: 130px;
          }
          .tgItem3{
            padding: 13px 0;
          }
          .tgItem4{
            width: 166px;
          }
          .tgItem5{
            width: 250px;
          }
          .tgItemData{
            color: #58667E;
          }
        }
        .tgWrap:not(:last-child){
          border-bottom: 1px solid #E0E0E1;
        }
        .tgTitleWrap{
          background: #CCCCCC;
        }
      }
    }
  }

}
.list{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 17px;
  margin-bottom: 30px;
  margin-right: 40px;
  .item{
    margin-top: 30px;
    margin-left: 50px;
    width: 450px;
    height: 319px;
    background: #FFFFFF;
    border-radius: 30px;
    cursor: pointer;
    .unitImg{
      width: 100%;
      height: 100%;
    }
  }
}
.echartsContainer{
  width: 100%;
  height: 100%;
  margin: 0 auto 60px;
  background: #FFFFFF;
  box-shadow: 0 8px 21px 0 rgba(88,102,126,0.11);
  border-radius: 15px;
  padding: 11px;
  position: relative;
  box-sizing: border-box;
  .chartNoData{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinWrap{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainWrap{
    width: 100%;
    position: absolute;
    top: 11px;
    left: 11px;
    display: flex;
    justify-content: space-between;
    .coinInfo{
      margin-top: 10px;
      display: flex;
      align-items: center;
      .logoWrap{
        width: 27px;
        height: 27px;
        .logoImg{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .homeLogoWrap{
        width: 46px;
        height: 46px;
      }
      .infoWrap{
        margin-left: 9px;
        .coinName{
          font-size: 12px;
          font-weight: 800;
          color: #0D1421;
        }
        .homeCoinName{
          font-size: 20px;
        }
        .coinMoney{
          display: flex;
          margin-top: 5px;
          .money{
            font-size: 18px;
            font-weight: 800;
            color: #0D1421;
            line-height: 18px;
            text-shadow: 0 12px 32px rgba(88,102,126,0.11);
          }
          .homeMoney{
            font-size: 30px;
          }
          .rate{
            margin-left: 4px;
            font-size: 12px;
            font-weight: bold;
            color: #23C448;
          }
          .homeRate{
            font-size: 20px;
          }
          .up {
            color: #23C448;
          }
          .down {
            color: #F93435;
          }
        }
        .homeCoinMoney{
          margin-top: 10px;
        }
      }
    }
    .rightData{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 30px;
      .btnWrap{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 15px;
        background: #F0F2F5;
        border-radius: 8px;
        position: relative;
        .btnIcon{
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-bottom: 4px;
        }
        .homeBtnIcon{
          width: 12px;
          height: 12px;
          margin-bottom: 2px;
        }
        .btnText{
          margin-left: 3px;
          font-size: 9px;
          font-weight: 500;
          color: #58667E;
        }
        .homeBtnText{
          font-size: 16px;
        }
      }
      .homeBtnWrap{
        width: 94px;
        height: 26px;
        border-radius: 13px;
      }
      .rightList{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .rightItem{
          margin-left: 6px;
          .rightTitle{
            font-size: 8px;
            color: #8F99AA;
          }
          .homeRightTitle{
            font-size: 14px;
          }
          .rightContent{
            font-size: 9px;
            font-weight: bold;
            color: #0D1421;
          }
          .homeRightContent{
            margin-top: 5px;
            font-size: 18px;
            font-weight: bold;
            color: #0D1421;
          }
        }
        .homeRightItem{
          margin-left: 30px;
        }
      }
    }
  }
  .chartProgressWrap{
    display: flex;
    width: 100%;
    height: 100%;
    .chartWrap{
      margin-top: 42px;
      width: calc(100% - 220px);
      height: 100%;
      position: relative;
      box-sizing: border-box;
    }
    .homeChartWrap{
      width: calc(100% - 240px);
      margin-top: 90px;
      height: 83%;
    }
    .chartTimeWrap{
      position: absolute;
      top: 80px;
      right: 254px;
      display: flex;
      .chartTimeItem{
        width: 31px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        font-size: 8px;
        font-weight: 500;
        color: #58667E;
        cursor: pointer;
      }
      .activeChartTimeItem{
        background: #F0F2F5;
      }
    }
    .progressWrap{
      margin-top: 70px;
      margin-left: 20px;
      .progressTitle{
        font-size: 12px;
        font-weight: 600;
        color: #0D1421;
      }
      .homeProgressTitle{
        font-size: 18px;
      }
      .progressList{
        margin-top: 10px;
        .progressItem{
          display: flex;
          .progressStatus{
            width: 30px;
            font-size: 8px;
            font-weight: 500;
            color: #23C448;
          }
          .homeProgressStatus{
            font-size: 13px;
          }
          .bidsProgressStatus{
            color: rgba(249, 52, 53, 1);
          }
          .progressImgWrap{
            width: 84px;
            height: 19px;
            transform: translateY(-6px);
          }
          .progressMoney{
            margin-left: 10px;
            font-size: 8px;
            font-weight: 600;
            color: #0D1421;
          }
          .homeProgressMoney{
            font-size: 13px;
          }
        }
        .homeProgressItem{
          margin-top: 15px;
        }
      }
    }
    .homeProgressWrap{
      margin-top: 120px;
    }
  }
}
.homeEchartsContainer{
  box-shadow: unset;
  border-radius: 30px;
  padding: 20px;
}
.addWrap{
  margin-left: 357px;
  margin-top: 58px;
  width: 250px;
  height: 50px;
  background: #11D49D;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .addImg{
    width: 38px;
    height: 38px;
  }
  .addText{
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
  }
}
.addWrapActive{
  opacity: 0.3;
}

.carouselContainer{
  width: 888px;
  margin-top: 50px;
  position: relative;
  .upcomingUnlock{
    //height: 280px;
    display: flex !important;
    justify-content: center;
  }
  .noToken{
    height: 200px;
    line-height: 200px;
    text-align: center;
    color: #58667E;
  }
  .rightArrowWrap{
    position: absolute;
    top: 32%;
    right: 30px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 9999px;
    background: #FFFFFF;
    box-shadow: 0 14px 36px 0 rgba(88,102,126,0.11);
    cursor: pointer;
    .rightArrowImg{
      width: 24px;
      height: 24px;
    }
  }
  .leftArrowWrap{
    left: 30px;
    right: 0;
    z-index: 99;
    .leftArrowImg{
      transform: rotate(180deg);
    }
  }
  .sizeWrap{
    position: absolute;
    bottom: -20px;
    left: 100px;
    .sizeList{
      width: 170px;
      display: flex;
      flex-wrap: wrap;
      .sizeItem{
        margin-top: 3px;
        margin-left: 3px;
        width: 25px;
        height: 17px;
        background: rgba(143, 153, 170, 0.4);
        border-radius: 2px;
      }
      .canSelectItem{
        background-color: #FFFFFF;
      }
      .currSize{
        position: absolute;
        top: 0;
        left: 0;
        width: 56px;
        height: 56px;
        margin-top: 3px;
        margin-left: 3px;
        background: rgba(143, 153, 170, 1);
        cursor: pointer;
      }
    }
    .sizeText{
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #58667E;
    }
  }
}

.upcomingWrap{
  margin-top: 30px;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: linear-gradient(184deg, rgba(255, 226, 204, 0.8) 0%, rgba(255,255,255,1) 32%);
  border-radius: 16px;
  box-sizing: border-box;
  .upcomingTitle{
    font-size: 11px;
    font-weight: 600;
    color: #0D1421;
  }
  .homeUpcomingTitle{
    font-size: 18px;
  }
  .upcomingList{
    display: flex;
    margin-top: 10px;
    .upcomingLeftWrap{
      width: 104px;
      background: rgba(249, 251, 253, 1);
      border-radius: 9px;
      padding: 6px;
      box-sizing: border-box;
      .upcomingLeftIcon{
        width: 22px;
        height: 22px;
        border-radius: 12px;
        background: #8F99AA;
      }
      .homeUpcomingLeftIcon{
        width: 36px;
        height: 36px;
        border-radius: 20px;
      }
      .upcomingLeftData{
        margin-top: 5px;
        font-size: 7px;
        font-weight: bold;
        color: #0D1421;
      }
      .homeUpcomingLeftData{
        margin-top: 8px;
        font-size: 12px;
        font-weight: bold;
        color: #0D1421;
      }
      .upcomingLeftMoney{
        margin-top: 4px;
        font-size: 7px;
        color: #8F99AA;
      }
      .homeUpcomingLeftMoney{
        font-size: 11px;
      }
    }
    .homeUpcomingLeftWrap{
      padding: 11px;
      width: 167px;
    }
    .upcomingRightWrap{
      padding: 4px;
      margin-left: 3px;
      width: 55px;
      height: 58px;
      background: #F9FBFD;
      border-radius: 9px;
      .upcomingRightMain{
        display: flex;
        align-items: center;
        position: relative;
        .upcomingRightIcon{
          position: absolute;
          top: -8px;
          left: -16px;
          width: 40px;
          height: 40px;
          .upcomingRightIconImg{
            width: 100%;
            height: 100%;
          }
        }
        .homeUpcomingRightIcon{
          position: absolute;
          top: -14px;
          left: -22px;
          width: 60px;
          height: 60px;
        }
        .upcomingRightTitle{
          margin-left: 12px;
          font-size: 7px;
          color: #58667E;
        }
        .homeUpcomingRightTitle{
          margin-left: 20px;
          font-size: 14px;
        }
      }
      .upcomingRightNumber{
        margin-left: 2px;
        font-size: 22px;
        font-weight: 600;
        color: #0D1421;
      }
      .homeUpcomingRightNumber{
        margin-top: 10px;
        font-size: 36px;
      }
    }
    .homeUpcomingRightWrap{
      width: 89px;
      height: 94px;
      border-radius: 14px;
      margin-left: 5px;
    }
  }
  .homeUpcomingList{
    margin-top: 15px;
  }
  .upcomingTime{
    margin-top: 9px;
    font-size: 7px;
    color: #8F99AA;
  }
  .homeUpcomingTime{
    margin-top: 14px;
    font-size: 12px;
  }
}

.homeUpcomingWrap{
  margin-top: 0;
  padding: 15px;
  border-radius: 14px;
  box-sizing: border-box;
}

.upcomingBlockWrap{
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  background: #FFFFFF;
  border-radius: 15px;
  box-sizing: border-box;
  .upcomingTopWrap{
    padding: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .upcomingTopTitle{
      font-size: 11px;
      font-weight: 600;
      color: #0D1421;
    }
    .homeUpcomingTopTitle{
      font-size: 18px;
    }
    .upcomingTopTime{
      font-size: 7px;
      color: #8F99AA;
    }
    .homeUpcomingTopTime{
      font-size: 12px;
    }
  }
  .homeUpcomingTopWrap{
    padding: 15px;
  }
  .upcomingInfoWrap{
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding: 5px 0;
    .upcomingIcon{
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #8F99AA;
    }
    .homeUpcomingIcon{
      width: 30px;
      height: 30px;
      border-radius: 17px;
    }
    .upcomingContentWrap{
      margin-left: 3px;
      .upcomingContentTitle{
        font-size: 7px;
        font-weight: bold;
        color: #0D1421;
      }
      .homeUpcomingContentTitle{
        font-size: 12px;
      }
      .upcomingContentMoney{
        margin-top: 2px;
        font-size: 7px;
        color: #8F99AA;
      }
      .homeUpcomingContentMoney{
        margin-top: 5px;
        font-size: 11px;
      }
    }
    .homeUpcomingContentWrap{
      margin-left: 5px;
    }
  }
  .homeUpcomingInfoWrap{
    margin-left: 8px;
    padding: 8px;
  }
  .allocationTitleWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 9px 0 9px;
    .allocationLeftWrap{
      display: flex;
      align-items: center;
      .allocationLeftIcon{
        width: 6px;
        height: 7px;
        border-radius: 50%;
        background: #58667E;
      }
      .homeAllocationLeftIcon{
        width: 10px;
        height: 11px;
      }
      .allocationLeftTitle{
        margin-left: 6px;
        font-size: 8px;
        color: #58667E;
      }
      .homeAllocationLeftTitle{
        margin-left: 10px;
        font-size: 14px;
      }
    }

    .allocationNumber{
      font-size: 7px;
      color: #8F99AA;
    }
    .homeAllocationNumber{
      font-size: 12px;
    }
  }
  .homeAllocationTitleWrap{
    padding: 15px 15px 0 15px;
  }
  .allocationList{
    margin: 5px 4px 0 9px;
    height: 130px;
    overflow-y: scroll;
    .allocationItem{
      width: 155px;
      background: #FEFEFE;
      border-radius: 4px;
      border: 1px solid #F0F2F5;
      box-sizing: border-box;
      padding: 4px 4px 4px 7px;
      margin-bottom: 6px;
      position: relative;
      .allocationItemType{
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background: #29E155;
        border-radius: 3px 0 0 3px;
      }
      .homeAllocationItemType{
        width: 5px;
        border-radius: 5px 0 0 5px;
      }
      .allocationTopWrap{
        display: flex;
        justify-content: space-between;
        .allocationTopTitle{
          font-size: 7px;
          font-weight: 600;
          color: #0D1421;
        }
        .homeAllocationTopTitle{
          font-size: 12px;
        }
        .allocationTopMoney{
          font-size: 7px;
          font-weight: 600;
          color: #0D1421;
        }
        .homeAllocationTopMoney{
          font-size: 12px;
        }
      }
      .allocationMiddleWrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1px;
        .allocationMiddleTitle{
          display: flex;
        }
        .allocationMiddleNumber{
          font-size: 7px;
          color: #0D1421;
        }
        .homeAllocationMiddleNumber{
          font-size: 11px;
        }
        .allocationMiddleTips{
          margin-left: 4px;
          font-size: 7px;
          color: #8F99AA;
        }
        .homeAllocationMiddleTips{
          margin-left: 7px;
          font-size: 11px;
        }
        .allocationMiddleMoney{
          font-size: 7px;
          color: #8F99AA;
        }
        .homeAllocationMiddleMoney{
          font-size: 11px;
        }
      }
      .homeAllocationMiddleWrap{
        margin-top: 3px;
      }
      .allocationBottomWrap{
        font-size: 6px;
        color: #8F99AA;
      }
      .homeAllocationBottomWrap{
        margin-top: 3px;
        font-size: 11px;
      }
    }
    .homeAllocationItem{
      width: 260px;
      padding: 6px 10px 6px 14px;
      border-radius: 6px;
    }
  }
  .homeAllocationList{
    height: 262px;
    margin: 10px 10px 0 15px;
  }
  /*滚动条样式*/
  .allocationList:hover::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
  }
  .allocationList:hover::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 0;
    background: transparent;
  }
  .allocationList::-webkit-scrollbar {
    width: 4px;
  }
  .allocationList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: unset;
    background: transparent;
  }
  .allocationList::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 0;
    background: transparent;
  }
}

.tokenPriceWrap{
  min-width: 240px;
  min-height: 140px;
  height: 100%;
  padding: 26px 22px 12px 16px;
  background: #FFFFFF;
  border-radius: 16px;
  box-sizing: border-box;
  .priceNoData{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinWrap{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeSpinWrap{
    height: 184px;
  }
  .tokenPriceInfo{
    display: flex;
    align-items: center;
    .logoWrap{
      width: 36px;
      height: 36px;
      .logoImg{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .homeLogoWrap{
      width: 56px;
      height: 56px;
      margin-left: 20px;
    }
    .infoWrap{
      margin-left: 11px;
      .coinName{
        font-size: 12px;
        font-weight: 800;
        color: #0D1421;
      }
      .homeCoinName{
        font-size: 18px;
      }
      .coinMoney{
        display: flex;
        align-items: baseline;
        margin-top: 5px;
        .money{
          font-size: 19px;
          font-weight: 800;
          color: #0D1421;
          line-height: 18px;
          text-shadow: 0 12px 32px rgba(88,102,126,0.11);
        }
        .homeMoney{
          font-size: 22px;
          font-weight: 800;
        }
        .rate{
          margin-left: 5px;
          font-size: 12px;
          font-weight: bold;
          color: #23C448;
        }
        .homeRate{
          font-size: 18px;
          font-weight: bold;
          margin-left: 5px;
        }
        .up {
          color: #23C448;
        }
        .down {
          color: #F93435;
        }
      }
      .homeCoinMoney{
        margin-top: 10px;
      }
    }
    .homeInfoWrap{
      margin-left: 10px;
    }
  }
  .homeTokenPriceInfo{
    margin-top: 32px;
  }
  .tokenPriceVolume{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 4px 0;
    .tokenPriceVolumeTitle{
      font-size: 7px;
    }
    .homeTokenPriceVolumeTitle{
      font-size: 12px;
      color: #8F99AA;
    }
    .tokenPriceVolumeMoney{
      margin-left: 7px;
      font-size: 8px;
      font-weight: 600;
    }
    .homeTokenPriceVolumeMoney{
      font-size: 12px;
      font-weight: 500;
      color: #0D1421;
    }
  }
  .homeTokenPriceVolume{
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
}
.homeTokenPriceWrap{
  padding: 10px;
  margin-top: 8px;
  background: #FFFFFF;
  border-radius: 30px;
  box-sizing: border-box;
}

//.wrap{
//  transform: scale(1) translateY(100vh);
//  animation: moveUpDown 0.4s ease-in-out 0.2s forwards;
//}
//@keyframes moveUpDown {
//  0% { transform: translateY(100vh); }
//  100% { transform: translateY(0); }
//}