:global{
  .ant-table-row .ant-table-row-level-0{
    cursor: pointer;
  }
}
.home_wrap {
  flex: 1;
  width: 100%;
  .home_container {
    width: 100%;
    max-width: 1270px;
    margin: auto;
    .home_title {
      margin-top: 94px;
      font-size: 28px;
      font-family: "SFProRounded", sans-serif;
      font-weight: 800;
      color: #0D1421;
    }
    .home_small_title {
      margin-top: 10px;
      font-size: 14px;
      font-family: "SFProRounded", sans-serif;
      font-weight: 400;
      color: #58667E;
      line-height: 14px;
    }
    .home_card_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .home_card {
        margin-top: 30px;
        overflow: hidden;
        position: relative;
        width: 306px;
        height: 184px;
        padding: 20px 16px;
        background: #fff;
        box-shadow: 0 10px 25px 0 rgba(88,102,126,0.11);
        border-radius: 10px;
        border: 1px solid #FBFBFB;
        .home_card_title {
          font-size: 18px;
          font-family: "SFProRounded", sans-serif;
          font-weight: 600;
          color: #0D1421;
          line-height: 20px;
          display: flex;
          align-items: center;
          img {
            width: 22px;
            height: 19px;
            margin-right: 3px;
          }
          .far{
            width: 19px;
            height: 22px;
          }
          .hj{
            width: 19px;
          }
          p {
            margin-left: 2px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

        }
        .home_card_item {
          display: flex;
          justify-content: space-between;
          .home_card_item_l {
            margin-top: 15px;
            display: flex;
            align-items: center;
            color: #0D1421;
            img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              margin-right: 7px;
            }
            p {
              font-size: 13px;
              font-family: "SFProRounded", sans-serif;
              font-weight: 500;
              cursor: pointer;
            }
          }
          .home_card_item_r {
            margin-top: 15px;
            display: flex;
            align-items: center;
            .home_card_item_r_imgs {
              display: flex;
              align-items: center;
              img {
                margin-left: -3px;
                width: 18px;
                height: 18px;
              }
              img:first-child {
                margin-left: 0;
              }
              .home_card_item_r_imgs_total {
                height: 18px;
                background: #F6F6F7;
                border-radius: 14px;
                border: 1px solid #FFFFFF;
                font-family: "SFProRounded", sans-serif;
                font-weight: 500;
                padding: 0 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: -3px;
                color: #58667E;
                font-size: 10px;
              }
            }
            .home_card_item_r_text {
              font-size: 13px;
              margin-left: 10px;
              font-family: "SFProRoundedMedium", sans-serif;
              font-weight: 500;
              color: #0D1421;
            }
            .up {
              color: #23C448;
            }
            .down {
              color: #F93435;
            }
          }
        }
      }
      .home_card:last-child {
        padding: 0;
        position: relative;
        border-radius: 20px;
        .bg_img{
          width: 306px;
          height: 184px;
          position: absolute;
          top: -16px;
          left: 0;
        }
        :global {
          .ant-carousel .slick-dots li button {
            width: 4px;
            height: 4px;
            opacity: 0.2;
          }
          .ant-carousel .slick-dots li.slick-active button {
            opacity: 1;
            background: #fff;
          }
          .ant-carousel .slick-dots li {
            width: 4px;
          }
          .ant-carousel .slick-dots-bottom {
            bottom: 64px;
          }
        }
        .banner {
          width: 80%;
          height: 165px;
          .title{
            font-size: 18px;
            font-weight: 900;
            color: #FFFFFF;
            text-align: center;
          }
          .content{
            width: 80%;
            overflow: hidden;
            margin: 0 auto;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            font-size: 10px;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
          }
        }
        .banner_left {
          position: absolute;
          top: 0; bottom: 0;
          left: 10px;
          margin: auto;
          z-index: 2;
          width: 16px;
          cursor: pointer;
          height: 16px;
        }
        .banner_right {
          position: absolute;
          top: 0; bottom: 0;
          right: 10px;
          margin: auto;
          z-index: 2;
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .btn_wrap {
          position: absolute;
          left: 0; right: 0;
          bottom: 15px;
          height: 40px;
          z-index: 99;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          img {
            margin-right: 6px;
            width: 22px;
            height: 22px;
          }
          .btn_left {
            cursor: pointer;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 8px 22px;
            font-size: 14px;
            font-family: "SFProRounded", sans-serif;
            font-weight: bold;
            color: #0D1421;
            display: flex;
            align-items: center;
          }
          .btn_right {
            cursor: pointer;
            background: #11D49D;
            border-radius: 12px;
            padding: 8px 22px;
            font-size: 14px;
            font-family: "SFProRounded", sans-serif;
            font-weight: bold;
            color: #ffffff;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .tab_module {
      display: flex;
      margin-top: 27px;
      margin-bottom: 17px;
      .tab {
        cursor: pointer;
        padding: 6px 12px;
        margin-right: 20px;
        font-size: 16px;
        color: #58667E;
        background: transparent;
        font-family: "SFProRounded", sans-serif;
        font-weight: 600;
        border-radius: 8px;
      }
      .tab_active {
        background: #11D49D;
        color: #fff;
      }
    }
    .list{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      .tab_wrap {
        margin-left: 10px;
        :global {
          .ant-tabs-tab {
            padding: 0 !important;
            .ant-tabs-tab-btn {
              color: #58667E;
              font-size: 16px;
              font-weight: 400;
              font-family: "SFProRounded", sans-serif;
            }
          }
          .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #0D1421 !important;
            font-weight: 500;
          }
          .ant-tabs .ant-tabs-ink-bar {
            background: #11D49D;
          }
          .ant-tabs-nav {
            margin-bottom: 0;
          }
          .ant-tabs-nav::before {
            border: none;
          }
          .ant-table-wrapper .ant-table-tbody >tr >td{
            text-align: right;
          }
        }
      }
      .record{
        width: 30px;
        height: 32px;
        cursor: pointer;
      }
      .record_model_wrap{
        position: absolute;
        top: 15px;
        right: -140px;
        width: 140px;
        height: 140px;
        border-radius: 10px;
        background: rgba(238, 238, 238, 1);
        padding: 10px;
        .record_model_title{
          font-size: 16px;
          opacity: 0.6;
        }
      }
    }
    .table_wrap{
      margin-top: 20px;
      :global {
        .ant-table-wrapper .ant-table-thead th{
          background: #FFFFFF;
        }
        .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
          width: 0px;
        }
      }
    }
  }
}
.cell200{
  width: 150px;
  text-align: right;
  .up {
    color: #23C448;
  }
  .down {
    color: #F93435;
  }
}

.home_card_item_r_imgs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    margin-left: -3px;
    width: 18px;
    height: 18px;
  }
  img:first-child {
    margin-left: 0;
  }
  .home_card_item_r_imgs_total {
    height: 18px;
    background: #F6F6F7;
    border-radius: 14px;
    border: 1px solid #FFFFFF;
    font-family: "SFProRounded", sans-serif;
    font-weight: 500;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -3px;
    color: #58667E;
    font-size: 10px;
  }
}
.percentWrap{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
 .percentItem{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
   .percent{
      font-size: 12px;
      color: #5F6D84;
    }
    .buyOrdersProgress{
      width: 100%;
      height: 5px;
      background: rgba(249, 52, 53, 1);
      border-radius: 3px;
      position: relative;
    }
    .sellOrdersProgress{
      margin-left: 2px;
      width: 100%;
      height: 5px;
      background: rgba(35, 196, 72, 1);
      border-radius: 3px;
      position: relative;
    }
}
}
.likeIcon{
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.nameWrap{
  display: flex;
  align-items: center;
 .nameIcon{
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
  .name{
    margin-left: 3px;
    font-size: 14px;
    font-weight: 600;
    color: #0D1421;
  }
}

.radioRightWrap{
  display: flex;
  align-items: center;
  margin-top: 10px;
  .selectImg{
    width: 16px;
    height: 16px;
    opacity: 0.6;
  }
  .coinImg{
    margin-left: 8px;
    width: 16px;
    height: 16px;
    text-align: center;
    opacity: 1;
  }
  .radioText{
    margin-left: 4px;
    font-size: 14px;
    color: #000000;
    opacity: 1;
  }
}