.wrap_line {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .menu {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu_active {
      background: #fff !important;
      font-weight: 600;
      color: #0D1421;
    }
    .menu_left {
      padding: 4px;
      box-sizing: border-box;
      background: #F0F2F5;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #58667E;
      display: flex;
      .menu_left_item {
        cursor: pointer;
        background: transparent;
        margin-right: 4px;
        border-radius: 6px;
        padding: 4px 15px;
        box-sizing: border-box;
      }
      .menu_left_item:last-child {
        margin-right: 0;
      }
    }
    .menu_right {
      display: flex;
      justify-content: flex-end;
      .menu_right_active {
        background: #F0F2F5 !important;
        color: #0D1421 !important;
      }
      .menu_right_item {
        cursor: pointer;
        padding: 8px 18px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 600;
        color: #58667E;
        margin-left: 5px;
        background: transparent;
        border-radius: 8px;
      }
    }
  }
  .charts {
    flex: 1;
    width: 100%;
  }
}