.container{
    width: 100%;
    max-width: 1270px;
    margin: auto;
    display: flex;
    .containerLeft{
      margin-top: 50px;
      padding: 50px 15px 50px 0;
      border-right: 1px solid #F0F2F5;
      display: flex;
      flex-direction: column;
      align-items: center;
      .headerImgWrap{
        width: 90px;
        height: 90px;
        border-radius: 45px;
        border: 1px solid #FBFBFB;
        background: #8D9AB1;
        .headerImg{
          width: 100%;
          height: 100%;
        }
      }
      .userName{
        margin-top: 15px;
        font-size: 20px;
        font-weight: 800;
        color: #0D1421;
      }
      .addressWrap{
        display: flex;
        align-items: center;
        margin-top: 10px;
        .address{
            font-size: 14px;
            color: #8F99AA;
        }
        .copyIcon{
            width: 11px;
            height: 11px;
            margin-left: 7px;
            cursor: pointer;
        }
        .copyIcon:hover{
          transform: scale(1.3);
        }
      }
      .tips{
        width: 186px;
        margin-top: 16px;
        font-size: 12px;
        color: #8F99AA;
      }
      .tabsWrap{
        margin-top: 50px;
        .tabItem{
            width: 192px;
            height: 50px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding-left: 15px;
            cursor: pointer;
            .line{
                width: 3px;
                height: 14px;
                background: #11D49D;
                border-radius: 2px;
            }
            .tabText{
                font-size: 16px;
                color: #58667E;
            }
            .activeTabText{
                font-size: 18px;
                font-weight: bold;
                margin-left: 7px;
            }
        }
        .activeTabItem{
            width: 192px;
            height: 50px;
            background: rgba(143, 153, 170, 0.1);
        }
      }
    }
    .containerRight{
        padding-top: 70px;
        margin-left: 33px;
        flex: 1;
        .titleWrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .containerRightTitle{
                margin-top: 50px;
                font-size: 28px;
                font-weight: 800;
                color: #0D1421;
            }
            .addWrap{
                margin-left: 357px;
                margin-top: 58px;
                width: 250px;
                height: 50px;
                background: #11D49D;
                border-radius: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .addImg{
                  width: 20px;
                  height: 20px;
                }
                .addText{
                    margin-left: 10px;
                  font-size: 18px;
                  font-weight: 600;
                  color: #FFFFFF;
                }
              }
        }
        .searchWrsp{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .searchItem{
                width: 250px;
                height: 28px;
                background: #F0F2F5;
                border-radius: 8px;
                display: flex;
                align-items: center;
                padding-left: 13px;
                box-sizing: border-box;
                .searchIcon{
                    width: 16px;
                    height: 16px;
                }
                .searchInput{
                    border: none;
                    background-color: #F0F2F5;
                }
            }
            .checkWrap{
                width: 60px;
                height: 34px;
                background: #F0F2F5;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                .checkItem{
                    width: 26px;
                    height: 26px;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .checkImg{
                        width: 16px;
                        height: 16px;
                    }
                }
                .activeCheckItem{
                    background: #FFFFFF;
                }
            }
        }
        .botsListWrap{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .botsItemWrap{
                width: 326px;
                height: 426px;
                padding: 20px;
                margin-top: 20px;
                background: #FFFFFF;
                border-radius: 18px;
                border: 1px solid #E3E5F4;
                .botsInfo{
                    width: 286px;
                    height: 46px;
                    padding: 8px;
                    background: rgba(240, 242, 245, 0.6);
                    border-radius: 8px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .botsInfoLeft{
                        display: flex;
                        align-items: center;
                        .botsLogo{
                            width: 30px;
                            height: 30px;
                            border-radius: 17px;
                        }
                        .botsName{
                            margin-left: 5px;
                            font-size: 12px;
                            font-weight: bold;
                            color: #0D1421;
                        }
                    }
                    .botsInfoRight{
                        .botsPrice{
                            font-size: 14px;
                            font-weight: bold;
                            color: #0D1421;
                        }
                        .botsChange{
                            margin-top: 4px;
                            font-size: 11px;
                            text-align: right;
                        }
                        .up{
                            color: #23C448;
                        }
                        .down{
                            color: #FF4949;
                        }
                    }
                }
                .chartWrap{
                    width: 228px;
                    height: 228px;
                    margin: 0 auto;
                    margin-top: -34px;
                }
                .chartList{
                    margin-top: 4px;
                    .chartItem{
                        display: flex;
                        justify-content: space-between;
                        padding: 11px 0;
                        font-size: 13px;
                        color: #0D1421;
                        .chartNumGreen{
                            font-weight: bold;
                            color: #23C448;
                        }
                    }
                    .chartItem:not(:last-child) {
                        border-bottom: 1px solid #F0F2F5;
                    }
                }
            }
        }
        .tableWrap{
            margin-top: 20px;
            border-top: 1px solid #EFF2F5;
            :global {
              .ant-table-wrapper .ant-table-thead th{
                background: #FFFFFF;
              }
              .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
                width: 0px;
              }
            }
          }
    }
}

.bot_delete_wrap {
    width: 100%;
    height: 100%;
    background: #EEEEEE;
    border-radius: 20px;
    .delete_tips {
      text-align: center;
      font-size: 28px;
      font-family: SFProRounded, sans-serif;
      font-weight: 800;
      color: #0D1421;
    }
    .delete_card_wrap{
        padding: 0 30px;
        .delete_card {
            box-sizing: border-box;
            .text{
              margin-top: 20px;
              font-size: 20px;
              font-weight: 500;
              color: #0D1421;
            }
            .searchInput{
              margin-top: 14px;
              padding: 12px;
              border: none;
              border-radius: 8px;
              background: #fff;
              box-shadow: 0 7px 18px 0 rgba(88,102,126,0.11);
              .searchIcon{
                width: 22px;
                height: 22px;
                margin-bottom: 4px;
              }
              :global{
                .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
                  font-size: 20px;
                }
              }
            }
          }
    }
    .delete_btn_wrap {
      margin-top: 40px;
      display: flex;
      justify-content: space-evenly;
      font-size: 20px;
      font-family: SFProRounded, sans-serif;
      font-weight: 600;
      color: #58667E;
      .delete_cancel {
        cursor: pointer;
        padding: 6px 24px;
        box-sizing: border-box;
        border: 1px solid #8F99AA;
        border-radius: 12px;
      }
      .delete_confirm {
        cursor: pointer;
        padding: 6px 24px;
        box-sizing: border-box;
        background: #11D49D;
        color: #fff;
        border-radius: 12px;
      }
      .delete_confirm_no_select{
        opacity: 0.6;
      }
    }
  }

  .optionWrap{
    display: flex;
    align-items: center;
    padding: 10px 0;
    .selectImg{
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .searchText{
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #0D1421;
    }
  }
  .card_item_r{
    display: flex;
    .up {
        color: #23C448;
      }
      .down {
        color: #F93435;
      }
  }
  :global {
    .ant-modal .ant-modal-content {
      min-height: 256px;
      border-radius: 20px;
      background: #EEEEEE;
    }
}