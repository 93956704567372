:global {
    .ant-modal .ant-modal-content {
      min-height: 268px;
      border-radius: 20px;
      background: #EEEEEE;
    }
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
        font-size: 28px;
        font-weight: 800;
        color: #0D1421;
    }
    .infoWrap{
        width: 500px;
        height: 64px;
        background: #FFFFFF;
        box-shadow: 0px 7px 18px 0px rgba(88,102,126,0.11);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin-top: 60px;
        .infoText{
            font-size: 20px;
            font-weight: bold;
            color: #0D1421;
        }
        .deleteIcon{
            width: 24px;
            height: 24px;
        }
    }
    .deleteBtn{
        width: 250px;
        height: 50px;
        background: #FF5E5F;
        border-radius: 12px;
        font-size: 20px;
        font-weight: 600;
        color: #FFFFFF;
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
