.bot_delete_wrap {
  width: 100%;
  height: 100%;
  background: #EEEEEE;
  border-radius: 20px;
  .delete_tips {
    text-align: center;
    font-size: 28px;
    font-family: SFProRounded, sans-serif;
    font-weight: 800;
    color: #0D1421;
  }
  .delete_card {
    padding: 18px 15px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    margin-top: 54px;
    margin-bottom: 60px;
    box-shadow: 0 7px 18px 0 rgba(88,102,126,0.11);
    font-family: SFProRounded, sans-serif;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #0D1421;
    }
    .value {
      font-size: 14px;
      font-weight: 400;
      color: #58667E;
    }
  }
  .delete_btn_wrap {
    display: flex;
    justify-content: space-evenly;
    font-size: 20px;
    font-family: SFProRounded, sans-serif;
    font-weight: 600;
    color: #58667E;
    .delete_cancel {
      cursor: pointer;
      padding: 6px 24px;
      box-sizing: border-box;
      border: 1px solid #8F99AA;
      border-radius: 12px;
    }
    .delete_confirm {
      cursor: pointer;
      padding: 6px 24px;
      box-sizing: border-box;
      background: #FF5E5F;
      color: #fff;
      border-radius: 12px;
    }
  }
}
.popover_title {
  width: 100%;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  font-family: SFProRounded, sans-serif;
  .bot_icon {
    width: 26px;
    height: 26px;
    margin-left: 6px;
  }
}
.bot_content {
  max-width: 300px;
  .bot_tips {
    font-size: 12px;
    font-family: SFProRounded, sans-serif;
    font-weight: 400;
    color: #58667E;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .bot_all_switch {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 10px;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    font-family: SFProRounded, sans-serif;
    font-weight: 500;
    color: #58667E;
  }
  .switch {
    background: #11D49D;
  }
  .bot_switch {
    background: #FFFFFF;
    box-shadow: 0 7px 18px 0 rgba(88,102,126,0.11);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    .bot_switch_top {
      font-size: 14px;
      font-family: SFProRounded, sans-serif;
      font-weight: 600;
      color: #0D1421;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bot_switch_top_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .bot_delete {
          width: 24px;
          height: 24px;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
    .bot_switch_bot {
      font-size: 12px;
      font-family: SFProRounded, sans-serif;
      font-weight: 400;
      color: #58667E;
    }
  }
  .bot_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .disassociate {
      flex: 1;
      cursor: pointer;
      padding: 10px 15px;
      box-sizing: border-box;
      background: #FBFBFB;
      border-radius: 12px;
      border: 1px solid #8F99AA;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      font-size: 14px;
      font-family: SFProRounded, sans-serif;
      font-weight: 600;
      color: #58667E;
    }
    .subscribe {
      width: 140px;
      cursor: pointer;
      padding: 10px 0;
      box-sizing: border-box;
      background: #11D49D;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      font-family: SFProRounded, sans-serif;
      font-weight: 600;
    }
  }
}

.header_wrap {
  width: 100%;
  min-width: 920px;
  height: 66px;
  border-bottom: 1px solid #EFF2F5;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .header_left {
    display: flex;
    align-items: center;
    :global {
      .ant-tabs-tab {
        padding: 0 !important;
        .ant-tabs-tab-btn {
          color: rgb(118, 125, 139);
          font-size: 16px;
          font-weight: 400;
        }
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #0D1421 !important;
        font-weight: 600;
      }
      .ant-tabs-nav {
        margin-bottom: 0;
      }
      .ant-tabs-nav::before {
        border: none;
      }
    }
    .logo {
      width: 176px;
      height: 43px;
      margin-right: 100px;
      cursor: pointer;
    }
  }
  .header_right {
    display: flex;
    align-items: center;
    position: relative;
    .accountWrap{
      position: absolute;
      top: 40px;
      right: 0;
      width: 360px;
      height: 195px;
      background: #FFFFFF;
      box-shadow: 0px 10px 25px 0px rgba(88,102,126,0.11);
      border-radius: 15px;
      border: 1px solid #F0F2F5;
      .title{
        margin: 30px 0 0 30px;
      }
      .account_list{
        margin-top: 20px;
        .account_item{
          width: 332px;
          height: 50px;
          margin: 5px auto;
          border-radius: 10px;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .account_left{
            display: flex;
            align-items: center;
           .account_icon{
              width: 34px;
              height: 34px;
              margin-right: 10px;
            }
           .account_name{
              font-size: 16px;
              font-weight: 500;
              color: #333333;
          }
          }
          .account_right{
            width: 16px;
            height: 16px;
          }
        }
        .account_item:hover{
          background: rgba(143, 153, 170, 0.1);
        }
      }
    }
    .icon {
      width: 34px;
      height: 34px;
      margin-right: 16px;
      cursor: pointer;
    }
    .bot_btn {
      margin-right: 20px;
      border-radius: 12px;
      border: 1px solid #11D49D;
      padding: 7px 21px;
      box-sizing: border-box;
      color: #11D49D;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      .bot_icon {
        width: 26px;
        height: 26px;
        margin-right: 6px;
      }
    }
    .bot_btn:hover {
      background: rgba(17, 212, 157, 0.1);
    }
    .connectWallet_btn {
      border: 1px solid #11D49D;
      //background: rgba(17, 212, 157, 0.1);
      border-radius: 12px;
      padding: 7px 21px;
      box-sizing: border-box;
      color: rgba(17, 212, 157, 1);
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 26px;
        height: 26px;
        margin-right: 6px;
      }
    }
    .connectWallet_btn:hover {
      background: rgba(17, 212, 157, 0.1);
    }
    .infoWrap{
      min-width: 140px;
      min-height: 38px;
      background: rgba(143, 153, 170, 0.1);
      border-radius: 12px;
      padding: 12px 36px;
      box-sizing: border-box;
      color: rgba(17, 212, 157, 1);
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      .infoText{
        font-size: 18px;
        font-weight: 600;
        color: #0D1421;
      }
    }
  }
}


