.upcomingBlockWrap{
  width: 292px;
  height: 340px;
  margin-bottom: 40px;
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #E3E5F4;
  .upcomingTopWrap{
    padding: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .upcomingTopTitle{
      font-size: 11px;
      font-weight: 600;
      color: #0D1421;
    }
    .homeUpcomingTopTitle{
      font-size: 18px;
    }
    .upcomingTopTime{
      font-size: 7px;
      color: #8F99AA;
    }
    .homeUpcomingTopTime{
      font-size: 12px;
    }
  }
  .homeUpcomingTopWrap{
    padding: 15px;
  }
  .unlockWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 266px;
    height: 62px;
    background: rgba(240, 242, 245, 0.6);
    border-radius: 6px;
    margin: 0 auto;
    .upcomingInfoWrap{
      display: flex;
      margin-left: 15px;
      padding: 5px 0;
      .upcomingIcon{
        .upcomingIconImg{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .homeUpcomingIcon{
        .cliffImg{
          width: 12px;
          height: 11px;
        }
        .linearImg{
          width: 16px;
          height: 16px;
        }
      }
      .upcomingContentWrap{
        margin-left: 3px;
        .unlockName{
          font-size: 11px;
          color: #0D1421;
        }
        .upcomingContentTitle{
          font-size: 7px;
          font-weight: bold;
          color: #0D1421;
        }
        .homeUpcomingContentTitle{
          margin-top: 4px;
          font-size: 12px;
        }
        .cirSupply{
          margin-top: 5px;
          font-size: 11px;
          color: #8F99AA;
        }
      }
      .homeUpcomingContentWrap{
        margin-left: 10px;
      }
    }
    .homeUpcomingInfoWrap{
      margin-left: 8px;
      padding: 8px;
    }
    .upcomingContentMoney{
      margin-top: 2px;
      font-size: 7px;
      color: #8F99AA;
    }
    .homeUpcomingContentMoney{
      margin-right: 10px;
      font-size: 12px;
      font-weight: 600;
      color: #0D1421;
    }
  }
  .allocationTitleWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 9px 0 9px;
    .allocationLeftWrap{
      display: flex;
      align-items: center;
      .allocationLeftIcon{
        width: 6px;
        height: 7px;
      }
      .homeAllocationLeftIcon{
        width: 10px;
        height: 11px;
        .allocationImg{
          width: 100%;
          height: 100%;
          margin-bottom: 10px;
        }
      }
      .allocationLeftTitle{
        margin-left: 6px;
        font-size: 8px;
        color: #58667E;
      }
      .homeAllocationLeftTitle{
        margin-left: 10px;
        font-size: 14px;
      }
    }

    .allocationNumber{
      font-size: 7px;
      color: #8F99AA;
    }
    .homeAllocationNumber{
      font-size: 12px;
    }
  }
  .homeAllocationTitleWrap{
    padding: 10px 15px 0 15px;
  }
  .allocationList{
    margin: 5px 4px 0 9px;
    height: 130px;
    overflow-y: scroll;
    .allocationItem{
      width: 155px;
      background: #FEFEFE;
      border-radius: 4px;
      border: 1px solid #F0F2F5;
      box-sizing: border-box;
      padding: 4px 4px 4px 7px;
      margin-bottom: 6px;
      position: relative;
      .allocationItemType{
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background: #29E155;
        border-radius: 3px 0 0 3px;
      }
      .homeAllocationItemType{
        width: 5px;
        border-radius: 5px 0 0 5px;
      }
      .allocationTopWrap{
        display: flex;
        justify-content: space-between;
        .allocationTopTitle{
          font-size: 7px;
          font-weight: 600;
          color: #0D1421;
        }
        .homeAllocationTopTitle{
          font-size: 12px;
        }
        .allocationTopMoney{
          font-size: 7px;
          font-weight: 600;
          color: #0D1421;
        }
        .homeAllocationTopMoney{
          font-size: 12px;
        }
      }
      .allocationMiddleWrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1px;
        .allocationMiddleTitle{
          display: flex;
        }
        .allocationMiddleNumber{
          font-size: 7px;
          color: #0D1421;
        }
        .homeAllocationMiddleNumber{
          font-size: 11px;
        }
        .allocationMiddleTips{
          margin-left: 4px;
          font-size: 7px;
          color: #8F99AA;
        }
        .homeAllocationMiddleTips{
          margin-left: 7px;
          font-size: 11px;
        }
        .allocationMiddleMoney{
          font-size: 7px;
          color: #8F99AA;
        }
        .homeAllocationMiddleMoney{
          font-size: 11px;
        }
      }
      .homeAllocationMiddleWrap{
        margin-top: 3px;
      }
      .allocationBottomWrap{
        font-size: 6px;
        color: #8F99AA;
      }
      .homeAllocationBottomWrap{
        margin-top: 3px;
        font-size: 11px;
      }
    }
    .homeAllocationItem{
      width: 260px;
      padding: 6px 10px 6px 14px;
      border-radius: 6px;
    }
  }
  .homeAllocationList{
    height: 182px;
    margin: 10px 10px 0 15px;
  }
  /*滚动条样式*/
  .allocationList:hover::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
  }
  .allocationList:hover::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 0;
    background: transparent;
  }
  .allocationList::-webkit-scrollbar {
    width: 4px;
  }
  .allocationList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: unset;
    background: transparent;
  }
  .allocationList::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 0;
    background: transparent;
  }
}