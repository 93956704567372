.setting {
  display: flex;
  flex-direction: column;
  .setting_title {
    margin: 50px 0;
    font-size: 28px;
    font-weight: 800;
    color: #0D1421;
  }
  .setting_text {
    font-size: 16px;
    font-weight: 400;
    color: #58667E;
  }
}