.container{
  width: calc(100vw - 20px);
  //min-height: calc(100vh - 45px);
  padding: 68px 0 0 50px;
  background: #FBFBFB;
  box-sizing: border-box;
  .tipsEditWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tipsWrap{
      .title{
        font-size: 28px;
        font-weight: 800;
        color: #0D1421;
      }
      .tips{
        margin-top: 10px;
        font-size: 14px;
        color: #58667E;
      }
    }
  }
  .navs_wrap {
    width: 100%;
    height: 60px;
    margin: 22px 0;
    display: flex;
    align-items: center;
    .navs_left {
      flex: 1;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      .navs_active {
        background: #F0F2F5 !important;
      }
      .navs_home {
        cursor: pointer;
        background: transparent;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: 10px 18px;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: 600;
        color: #0D1421;
        .home_icon {
          width: 16px;
          height: 15px;
          margin-right: 12px;
        }
      }
      .navs_items {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        overflow-x: auto;
        white-space: nowrap;

        .nav_item {
          cursor: pointer;
          background: transparent;
          border-radius: 12px;
          padding: 10px 16px;
          box-sizing: border-box;
          font-size: 18px;
          font-weight: 600;
          color: #0D1421;
          margin-left: 10px;
          position: relative;
          .delWrap{
            position: absolute;
            right: -8px;
            top: -8px;
            width: 18px;
            height: 18px;
            z-index: 10;
            background: #8D9AB1;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .del {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .navs_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .toScroll {
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 25px;
        margin-left: 25px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0px 14px 36px 0px rgba(88,102,126,0.11);
        img {
          width: 24px;
          height: 24px;
        }
      }
      .toScroll2 {
        margin-left: 0;
      }
      .editWrap{
        width: 166px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        padding: 12px 14px;
        background: #F0F2F5;
        border-radius: 12px;
        cursor: pointer;
        .editIcon{
          width: 20px;
          height: 20px;
        }
        .editText{
          margin-left: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #0D1421;
        }
        .editActiveText{
          color: #11D49D;
        }
      }
      .editActiveWrap{
        background: unset;
        border: 1px solid #11D49D;
      }
      .addWrap {
        margin-right: 25px;
        display: flex;
        align-items: center;
        padding: 12px 14px;
        background: #11D49D;
        border-radius: 12px;
        cursor: pointer;
        .editIcon{
          width: 20px;
          height: 20px;
        }
        .addText{
          margin-left: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #FFF;
        }
      }
    }
  }
  .layoutWrap{
    position: relative;
    margin-right: 14px;
    margin-bottom: 40px;
    .bgWrap{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: 10px;
      .bgItem{
        width: 15.7%;
        height: 200px;
        line-height: 200px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 26px;
        margin: 16px 0 0 0;
        cursor: pointer;
        .addImg{
          width: 44px;
          height: 44px;
        }
      }
      .bgItem:hover{
        background: rgba(240, 242, 245, 1);
      }
    }
    .layoutContent{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 994;
      .editWrap{
        position: absolute;
        top: -2px;
        right: 30px;
        z-index: 99;
        cursor: pointer;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #8D9AB1;
        display: flex;
        justify-content: center;
        align-items: center;
        .editICon{
          margin-left: 2px;
          margin-top: -6px;
          width: 16px;
          height: 16px;
        }
      }
      .removeWrap{
        position: absolute;
        top: -2px;
        right: -4px;
        z-index: 99;
        cursor: pointer;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #8D9AB1;
        display: flex;
        justify-content: center;
        align-items: center;
        .deleteICon{
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}