.detail_wrap {
  flex: 1;
  width: 100%;
  padding-top: 66px;
  box-sizing: border-box;
  .detail_content {
    width: 100%;
    max-width: 1270px;
    margin: auto;
    .detail_head {
      width: 100%;
      margin-top: 40px;
      display: flex;
      .detail_head_left {
        flex: 1;
        overflow: hidden;
        .detail_head_info{
          display: flex;
          .detail_head_icon {
            width: 76px;
            height: 76px;
            border-radius: 50%;
            margin-right: 10px;
            overflow: hidden;
            .detail_head_icon_img{
              width: 100%;
              height: 100%;
            }
          }
          .detail_head_info_right{
            margin-left: 10px;
            .detail_title {
              display: flex;
              align-items: center;
              .detail_head_name {
                font-size: 21px;
                font-weight: 800;
                color: #0D1421;
                margin-right: 10px;
              }
              .detail_head_tag {
                padding: 5px 9px;
                box-sizing: border-box;
                border-radius: 30px;
                background: #f0f2f5;
                font-size: 14px;
                font-weight: 500;
                color: #58667E;
              }
            }
          }
          .detail_price_wrap {
            display: flex;
            align-items: flex-end;
            margin-top: 10px;
            .detail_price {
              font-size: 40px;
              font-weight: 800;
              color: #0D1421;
            }
            .detail_price_precent {
              font-size: 22px;
              font-weight: bold;
              color: #23C448;
              margin-left: 5px;
              padding-bottom: 5px;
              box-sizing: border-box;
            }
            .up {
              color: #23C448;
            }
            .down {
              color: #F93435;
            }
          }
        }
        .detail_tips {
          margin-top: 25px;
          font-size: 14px;
          font-weight: 400;
          color: #58667E;
        }
      }
      .detail_head_right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 30px;
        .detail_links_wrap{
          display: flex;
          justify-content: flex-end;
          .detail_links {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px;
            font-weight: 400;
            color: #2E6EF7;
            margin-bottom: 12px;
            .links_icon {
              width: 32px;
              height: 32px;
              margin-left: 5px;
            }
          }
        }
        .detail_head_wrap_btn {
          display: flex;
          margin-top: 46px;
          justify-content: flex-end;
          .add_to_watch_list {
            cursor: pointer;
            padding: 12px 27px;
            box-sizing: border-box;
            border-radius: 12px;
            background: #F0F2F5;
            color: #0D1421;
            font-size: 18px;
            font-weight: 600;
          }
          .track_in_dashboard {
            cursor: pointer;
            padding: 12px 27px;
            margin-left: 20px;
            box-sizing: border-box;
            border-radius: 12px;
            background: #11D49D;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
    .detail_line {
      width: 100%;
      height: 1px;
      background: #F0F2F5;
      margin: 20px 0 10px 0;
    }
    .navs_wrap {
      padding: 10px 0 20px 0;
      box-sizing: border-box;
      position: sticky;
      top: 66px;
      z-index: 999;
      background: #fff;
      :global {
        .ant-tabs-tab {
          padding: 0 !important;
          .ant-tabs-tab-btn {
            color: #58667E;
            font-size: 16px;
            font-weight: 400;
            font-family: "SFProRounded", sans-serif;
          }
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #0D1421 !important;
          font-weight: 500;
        }
        .ant-tabs .ant-tabs-ink-bar {
          background: #11D49D;
        }
        .ant-tabs-nav {
          margin-bottom: 0;
        }
        .ant-tabs-nav::before {
          border: none;
        }
        .ant-table-wrapper .ant-table-tbody >tr >td{
          text-align: right;
        }
      }
    }
    .nav_items {
      display: flex;
      margin-top: 20px;
      align-items: center;
      justify-content: space-between;
      .nav_item {
        flex: 1;
        overflow: hidden;
        background: rgba(248, 250, 253, .6);
        border-radius: 10px;
        border: 1px solid #E3E5F4;
        padding: 20px;
        box-sizing: border-box;
        margin-right: 20px;
        .nav_item_child {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          :global{
            .ant-progress .ant-progress-text{
              font-size: 13px;
              font-weight: 600;
              color: #0D1421;
            }
          }
          .nav_item_child_left {
            font-size: 13px;
            font-weight: 500;
            color: #58667E;
          }
          .nav_item_child_right {
            font-size: 13px;
            font-weight: 600;
            color: #0D1421;
            .nav_item_child_right_color {
              color: #23C448;
            }
            .up {
              color: #23C448;
            }
            .down {
              color: #F93435;
            }
          }
        }
        .nav_item_child:last-child {
          margin-bottom: 0;
        }
      }
      .nav_item:last-child {
        margin-right: 0;
      }
    }
    .detail_dashboard {
      margin-top: 40px;
      width: 100%;
      display: flex;
      .detail_dashboard_price {
        flex: 0.9;
        background: rgba(248, 250, 253, .6);
        border-radius: 10px;
        border: 1px solid #E3E5F4;
        padding: 20px;
        box-sizing: border-box;
        margin-right: 24px;
        .detail_dashboard_price_title {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #0D1421;
          margin-bottom: 30px;
          font-weight: 600;
          .hot {
            margin-right: 5px;
          }
        }
        .detail_dashboard_price_items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .detail_dashboard_price_item {
            padding: 8px;
            margin-right: 6px;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0 10px 25px 0 rgba(88,102,126,0.11);
            border-radius: 8px;
            .detail_dashboard_price_item_top {
              display: flex;
              align-items: center;
              .redIcon {
                margin-left: 6px;
                width: 13px;
                height: 9px;
              }
            }
            .detail_dashboard_price_item_bot {
              font-size: 16px;
              font-weight: 600;
              color: #F93435;
              margin-top: 6px;
            }
            .detail_dashboard_price_item_bot_green {
              color: #23C448;
            }
            .detail_dashboard_price_item_bot_gray{
              color: #8D9AB1;
            }
          }
          .detail_dashboard_price_item:last-child {
            margin-right: 0;
          }
          .detail_dashboard_price_item:first-child{
            width: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .detail_dashboard_price_time {
          display: flex;
          align-items: center;
          margin-top: 30px;
          .detail_dashboard_price_time_item {
            cursor: pointer;
            margin-right: 5px;
            background: transparent;
            border-radius: 8px;
            padding: 8px 15px;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 500;
            color: #58667E;
          }
          .detail_dashboard_price_time_item_active {
            background: #11D49D;
            color: #fff;
          }
        }
        .detail_dashboard_price_data {
          .detail_dashboard_price_data_top {
            display: flex;
            margin-top: 15px;
            .detail_dashboard_price_data_item {
              flex: 1;
              font-size: 14px;
              font-weight: 400;
              color: #8F99AA;
              .detail_dashboard_price_data_item_val {
                font-size: 18px;
                font-weight: bold;
                color: #0D1421;
              }
            }
          }
        }
      }
      .detail_dashboard_data {
        flex: 2.05;
      }
    }
    .detail_events {
      margin-top: 40px;
      display: flex;
      .detail_event_line {
        width: 100%;
        height: 1px;
        background: #F0F2F5;
        margin: 10px 0;
      }
      .detail_event_title {
        font-size: 26px;
        font-weight: bold;
        color: #0D1421;
      }
      .detail_event_card {
        width: 318px;
        height: 340px;
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #E3E5F4;
        .detail_event_card_li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .detail_event_card_li_left {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 400;
            color: #58667E;
            .detail_event_card_li_yuan_green {
              background: #23C448 !important;
            }
            .detail_event_card_li_yuan_disable {
              background: #58667E !important;
            }
            .detail_event_card_li_yuan {
              width: 10px;
              height: 10px;
              background: #F93435;
              border-radius: 50%;
              margin-right: 5px;
            }
          }
          .detail_event_card_li_right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .detail_event_card_li_val {
              font-size: 13px;
              font-weight: bold;
              color: #0D1421;
            }
            .detail_event_card_li_tag {
              background: #F0F2F5;
              border-radius: 13px;
              padding: 3px 6px;
              box-sizing: border-box;
              font-size: 11px;
              font-weight: 500;
              color: #58667E;
              margin: 0 5px;
            }
            .detail_event_card_li_precent {
              width: 44px;
              text-align: right;
              font-size: 13px;
              font-weight: 500;
              color: #8F99AA;
            }
          }
        }
      }
      .detail_event_left {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .detail_event_right {
        flex: 1;
        padding-top: 31px;
        box-sizing: border-box;
        .detail_event_upgrade {
          background: #FFFFFF linear-gradient(204deg, rgba(204,255,247,0.43) 0%, rgba(255,255,255,0) 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .detail_event_upgrade_icon {
            width: 68px;
            height: 68px;
          }
          .detail_event_upgrade_title {
            margin-top: 2px;
            padding: 0 15px;
            box-sizing: border-box;
            text-align: center;
            font-size: 17px;
            font-weight: bold;
            color: #0D1421;
          }
          .detail_event_upgrade_title2 {
            margin-top: 8px;
            padding: 0 15px;
            box-sizing: border-box;
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            color: #8F99AA;
          }
          .detail_event_upgrade_btn {
            cursor: pointer;
            margin-top: 25px;
            width: 108px;
            height: 36px;
            background: #11D49D;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .detail_event_center {
        flex: 3;
        .detail_event_center_title{
          margin-left: 20px;
          font-size: 26px;
          font-weight: bold;
          color: #0D1421;
        }
        .unlockEventsWrap{
          width: 960px;
          margin-top: 20px;
          position: relative;
          //:global {
          //  .slick-track{
          //    width: 100% !important;
          //  }
          //}
          .otherDots{
            position: absolute;
            bottom: -11px;
            right: 46%;
            display: flex;
            .dot{
              width: 26px;
              height: 6px;
              background-color: rgba(35, 196, 72, 1);
              margin-left: 16px;
              border-radius: 2px;
            }
          }
          .noUnlock{
            height: 340px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          :global{
            .slick-dots li{
              margin: 0 52px;
            }
            .slick-dots li button {
              width: 26px;
              height: 6px;
              background-color: rgba(35, 196, 72, 0.4);
              border-radius: 2px;
              padding: 0;
            }
            .slick-dots .slick-active button{
              background-color: rgba(35, 196, 72, 1);
            }
            .slick-dots li button::before{
              content: '';
            }
          }
          .unlockEventsItem{
            margin-left: 15px;
          }
        }
      }
    }
    .detail_table_area {
      margin-top: 40px;
      .detail_table_head {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: space-between;
        .detail_table_title {
          font-size: 26px;
          font-weight: bold;
          color: #0D1421;
        }
        .detail_table_menu {
          display: flex;
          align-items: center;
          padding: 4px;
          box-sizing: border-box;
          background: #F0F2F5;
          border-radius: 8px;
          .detail_table_menu_item {
            padding: 4px 15px;
            margin-right: 4px;
            box-sizing: border-box;
            background: transparent;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 600;
            color: #58667E;
            cursor: pointer;
          }
          .detail_table_menu_item:last-child {
            margin-right: 0;
          }
          .detail_table_menu_active {
            background: #fff;
            color: #0D1421;
          }
        }
      }
    }
  }
}

.percentWrap{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .percentItem{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .percent{
      font-size: 12px;
      color: #5F6D84;
    }
    .buyOrdersProgress{
      width: 100%;
      height: 5px;
      background: rgba(249, 52, 53, 1);
      border-radius: 3px;
      position: relative;
    }
    .sellOrdersProgress{
      margin-left: 2px;
      width: 100%;
      height: 5px;
      background: rgba(35, 196, 72, 1);
      border-radius: 3px;
      position: relative;
    }
  }
}

.home_card_item_r_exchange_wrap{
  display: flex;
  .home_card_item_r_logo{
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
  .home_card_item_r_exchange{
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #0D1421;
  }
}
