.footer_wrap {
  width: 100%;
  min-width: 920px;
  border-top: 1px solid #EFF2F5;
  display: flex;
  justify-content: space-between;
  padding: 52px 50px 70px;
  box-sizing: border-box;
  .footer_left {
    display: flex;
    flex-direction: column;
    .footer_logo {
      width: 230px;
      height: 56px;
    }
    .footer_left_bottom {
      margin-top: 150px;
      .footer_left_bottom_top {
        display: flex;
        img {
          width: 34px;
          height: 34px;
          cursor: pointer;
        }
      }
      .footer_left_bottom_bot {
        font-size: 15px;
        color: #58667E;
        margin-top: 11px;
      }
    }
  }
  .footer_right {
    display: flex;
    margin-top: 18px;
    .footer_right_item:nth-child(3) {
      margin-right: 110px;
    }
    .footer_right_item {
      margin-left: 110px;
      .footer_item_top {
        font-size: 16px;
        color: #0D1421;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .footer_list_child {
        font-size: 14px;
        color: #58667E;
        font-weight: 500;
        margin-top: 20px;
      }
      .footer_list_child:hover {
        cursor: pointer;
        color: #11D49D;
      }
    }
  }
}