.container{
    display: flex;
    .containerLeft{
        .title{
            margin-top: 50px;
            font-size: 28px;
            font-weight: 800;
            color: #0D1421;
        }
        .tips{
            margin-top: 10px;
            font-size: 14px;
            color: #58667E;
        }
        .temp1{
            margin-top: 50px;
            .codeTitleWrap{
                width: 500px;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                color: #8F99AA;
                .askBot{
                    text-decoration: underline;
                }
            }
            .codeInputWrap{
                width: 500px;
                background: #F0F2F5;
                border-radius: 8px;
                padding: 10px 0;
                margin-top: 13px;
                .codeInput{
                    margin-left: 6px;
                    font-size: 14px;
                }
            }
            .codeBtn{
                width: 150px;
                height: 50px;
                margin-top: 30px;
                background: rgba(17, 212, 157, 0.5);
                border-radius: 8px;
                font-size: 18px;
                font-weight: 600;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .codeBtnActive{
                background: rgba(17, 212, 157, 1);
            }
        }
        .temp2{
            margin-top: 50px;
            .switchList{
                .switchItem{
                    width: 480px;
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;
                    .switchTitle{
                        font-size: 16px;
                        color: #58667E;
                    }
                    .switch{
                        background: rgba(17, 212, 157, 1);
                    }
                }
                .switchItem2{
                    width: 500px;
                    height: 50px;
                    padding: 13px;
                    background: #FFFFFF;
                    box-shadow: 0px 7px 18px 0px rgba(88,102,126,0.11);
                    border-radius: 8px;
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .titleWrap{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .icon{
                            width: 24px;
                            height: 24px;
                            cursor: pointer;
                        }
                        .switchTitle{
                            margin-left: 10px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #0D1421;
                        }
                    }
                    .switch{
                        background: rgba(17, 212, 157, 1);
                    }
                }
            }
        }
    }
    .containerRight{
        width: 326px;
        height: 173px;
        margin-top: 64px;
        margin-left: 42px;
    }
}
